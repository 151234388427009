.App {
  text-align: center;
}

.Content {
  margin-right: 10px;
  margin-left: 10px;
}

.App-songinfo {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: small;
  background-color: #F5F5F5;
  border-radius: 15px;
}

.App-speedsuggestion {
  padding: 6px;
  margin-right: 6px;
  background-color: rgb(0, 202, 219);
  border-radius: 15px;
}

.App-speedwarning {
  padding: 6px;
  margin-right: 6px;
  background-color: rgb(246, 80, 253);
  border-radius: 15px;
}

.App-header {
  background-color: #222;
  height: 50px;
  padding: 10px;
  color: white;
}

.description {
  font-size: large;
}

.footer {
  text-align: right;
  margin-right: 10px;
}
